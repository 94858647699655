<template>
    <div class="quick-links">
        <h3>Quick links</h3>
        <!--
        <ul>
            <li><a href="#" title="">First time to Commercial Banking online</a></li>
            <li><a href="#" title="">My activation code has expired</a></li>
            <li><a href="#" title="">I want to create a user</a></li>
            <li><a href="#" title="">I want to create a payment</a></li>
            <li><a href="#" title="">What are the available payment types</a></li>
        </ul>
        -->
        <ul v-if="faqs.length > 0">
            <li v-for="(item, index) in faqs" :key="index">
                <a :href="item.link" :title="item.question">{{item.question}}</a>
            </li>
        </ul>  
    </div>
</template>

<script>
export default {
    name: 'MainPageFaqSelectorQuickLinks',
    props: ['faqs'],
}
</script>

<style scoped>

    .quick-links {
        width: 48.5%;
        position: relative;
        background: #ffffff;
        padding: 25px;
    }

    .quick-links h3 {
        font-size: 1.5em;
        margin: 0 0 25px 0;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        color: #05286A;
    }

    .quick-links ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.125em;
    }

    .quick-links ul li a {
        padding: 0 0 10px 0;
        display: block;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        font-size: 1.0625rem;
    }

    .quick-links ul li a:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: translateY(-50%) rotate(45deg) skew(-6deg, -6deg);
        color: #005BA2;
        margin: 0 0 -5px 10px;
    }

    @media only screen and (max-width: 650px) {
        .quick-links {
            width: 100%;
            margin-bottom: 30px;
        }
    }

</style>
