<template>
    <div class="faq-selector" v-if="faqs.length > 0" :class="{visuallyhidden: this.isCustomPage}">
        <div class="wrapper">
            <h4>Related topics</h4>
            <ul>
                <li v-for="(item, index) in this.randomiseList" :key="index">
                    <template v-if="item.path.length > 0">
                        <a :href="'/' + item.path + '/article/' + titleToLink(item.faqdisplay)" :title="item.faqdisplay" :data-recid="item.recognition_id" :data-answerid="item.answer_id" @click.prevent="submitRequest(item)">{{ item.faqdisplay }}</a>
                    </template>
                    <template v-else>
                        <a :href="'/article/' + titleToLink(item.faqdisplay)" :title="item.faqdisplay" :data-recid="item.recognition_id" :data-answerid="item.answer_id" @click.prevent="submitRequest(item)">{{ item.faqdisplay }}</a>
                    </template>

                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'AnswerPageFaqSelector',
    props: ['faqs'],
    data() {
        return {
            limit: 20
        }
    },
    methods: {
        submitRequest: function(faq) {
            let title = faq.faqdisplay;
            title = title.toLowerCase();
            title = title.replace(/\ /g, '-');
            title = title.replace(/\?/gi, '');

            store.dispatch('setFaqDestinationId', faq.answer_id);

            if(faq.path.length > 0) {
                router.push({path: '/' + faq.path + '/article/' + title});
            }
            else {
                router.push({path: '/article/' + title});
            }
        },

        titleToLink(title) {
            let link = title;
            link = link.toLowerCase();
            link = link.replace(/ /g, '-');
            link = link.replace(/\?/gi, '');
            return link;
        }
    },
    computed: {
        randomiseList() {
            let s = this.faqs.slice().sort(() => 0.5 - Math.random());
            return s.slice(0, this.limit);
        },

        currentRoute() {
            return this.$route;
        },

        customPages() {
            return store.state.customPages;
        },

        isCustomPage() {
            let found = false;
            let route = this.currentRoute;
            let pages = this.customPages;

            for(var key in pages) {
                if((route.path == '/search' && pages[key].query == route.query.q) || pages[key].path == route.path) {
                found = true;
                break;
                }
            }
            return found;
        }
    },

    watch: {
        currentRoute(n) {
            return n;
        },

        customPages(n) {
            return n;
        },

        isCustomPage(n) {
            return n;
        }
    }
}
</script>

<style scoped>
    .faq-selector {
        background: #005BA2;
        padding: 24px;
    }


    h4 {
        color: #ffffff;
        font-size: 1.5em;
        font-weight: bold;
        margin: 0;
        padding: 0;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
    }

    .faq-selector ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .faq-selector ul li {
        width: 48%;
        padding: 24px 0 0 0;
    }

    .faq-selector a {
        color: #ffffff;
        position: relative;
        font-size: 1.0625em;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        font-weight: 500;
        text-decoration: underline;
    }

    .faq-selector a:hover {
        text-decoration: none;
    }

    .faq-selector a:after {
        content: "";
        border-style: solid;
        border-width: 2px 2px 0 0;
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: rotate(45deg) skew(-6deg, -6deg);
        color: #ffffff;
        margin: 0px 0px 1px 6px;
    }

    @media only screen and (max-width: 650px) {
        .faq-selector ul li {
            width: 100%;
        }
    }

</style>
