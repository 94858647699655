<template>
    <div class="category-selector" :class="{clean: clean}">
        <div class="wrapper">
            <div class="categories">
                <div class="category cat3" @click.prevent="goTo('/administration', 'Root.Administration')">
                    <h3>Administration</h3>
                    <a href="/administration" title="Find out more" @click.prevent="goTo('/administration', 'Root.Administration')">Find out more</a>
                </div>

                <div class="category cat4" @click.prevent="goTo('/how-to-use-cbo/payments', 'Root.How to use CBO.Payments')">
                    <h3>Payments</h3>
                    <a href="/how-to-use-cbo/payments" title="Find out more" @click.prevent="goTo('/how-to-use-cbo/payments', 'Root.How to use CBO.Payments')">Find out more</a>
                </div>

                <div class="category cat5" @click.prevent="goTo('/how-to-use-cbo/statements-and-reports')">
                    <h3>Statements and Reports</h3>
                    <a href="/how-to-use-cbo/statements-and-reports" title="Find out more" @click.prevent="goTo('/how-to-use-cbo/statements-and-reports', 'Root.How to use CBO.Statements and Reports')">Find out more</a>
                </div>

                <!--
                <div class="category cat1" @click.prevent="goTo('/how-to-use-cbo', 'Root.How to use CBO')">
                    <h3>How to use CBO</h3>
                    <a href="/how-to-use-cbo" title="Find out more" @click.prevent="goTo('/how-to-use-cbo', 'Root.How to use CBO')">Find out more</a>
                </div>

                <div class="category cat2" @click.prevent="goTo('/videos')">
                    <h3>Videos</h3>
                    <a href="/videos" title="Find out more" @click.prevent="goTo('/videos')">Find out more</a>
                </div>
                -->
            </div>
            <!--<a v-if="!clean" href="https://cbonline.lloydsbank.com" target="_blank" title="" class="external-link" rel="noopener">Go to Commercial Banking Online</a>-->
        </div>
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'MainPageMaincatSelector',
    props: ['clean'],
    methods: {
        goTo(url, maincat) {
            (arguments.length == 2) && store.dispatch('setCatDestination', maincat);
            router.push({path: url})
        }
    }
}
</script>

<style scoped>

    /*
    .category-selector {
        background: #EEF4E6;
    }
    */

    .category-selector.clean {
        background: none;
    }

    .wrapper {
        max-width: 930px;
        margin: 0 auto;
        padding: 30px 0 0 0;
    }

    .clean .wrapper {
        padding: 0 0 30px 0;
    }

    .categories {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .category {
        width: 32%;
        background: #005BA2;
        height: 250px;
        color: #ffffff;
        position: relative;
        transition: background .4s;
        float: left;
    }

    .ie .category {
        margin-right: 10px;
    }

    .category:hover {
        cursor: pointer;
        background: #375387;
    }

    .category:last-child {
        margin: 0 0 0 0;
    }

    .category:after {
        display: inline-block;
        content: '';
        text-decoration: none;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-size: 300px 300px;
        top: 20%;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;            
    }

    .category.cat1:after {
        background-position: -50px -55px;
        width: 38px;
        height: 37px;  
    }

    .category.cat2:after {
        background-position: -50px -100px;
        width: 33px;
        height: 42px;  
    }

    .category.cat3:after {
        background-position: 0 -150px;
        width: 45px;
        height: 44px;  
    }

    /* Payments */
    .category.cat4:after {
        background-position: 0 -55px;
        width: 45px;
        height: 35px;  
    }

    /* Statements and reports */
    .category.cat5:after {
        background-position: 0 -100px;
        width: 38px;
        height: 46px;  
    }

    .category h3 {
        text-align: center;
        position: absolute;
        width: 100%;
        top: 40%;
        font-size: 1.5em;
        font-weight: normal;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
    }

    .category a {
        color: #ffffff;
        display: block;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 5%;
        font-size: 1.0625em;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
    }

    .category a:hover {
        text-decoration: none;
    }

    .category a:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 6px;
        width: 6px;
        transform: translateY(-50%) rotate(45deg);
        color: #ffffff;
        margin: 0 0 -5px 5px;
    }

    .external-link {
        margin: 25px 0 0 0;
        display: block;
        text-align: center;
        position: relative;
        font-size: 1.0625em;
        clear: both;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
    }

    .external-link:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: translateY(-50%) rotate(45deg) skew(-6deg, -6deg);
        color: #005BA2;
        margin: -2px 0 0 10px; 
        position: absolute;
        top: 13px;     
    }

    @media only screen and (max-width: 966px) {
        .category-selector {
            padding: 0 18px 0 18px;
        }

        .category-selector.clean {
            padding: 0 0 0 0;
        }
    }

    @media only screen and (max-width: 650px) {
        .category {
            width: 100%;
            margin-bottom: 30px;
        }
    }

</style>
