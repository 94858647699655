<template>
    <div class="banner">
        <div class="wrapper">
            <div class="links">
                <a href="/how-to-use-cbo/payments/taking-action-on-a-payment/article/how-to-recall-a-bacs-payment" class="ext-link" target="_self">Find out more</a>
                <!--<a href="https://resources.lloydsbank.com/cbonlinesupport" class="ext-link" target="_blank" title="Find more help on the current site">Find more help on the current site</a>-->
                <!--<a href="/whats-new" class="ext-link" target="_self" title="See what's new">See what's new</a>-->
            </div>
            <div class="text">
                <p class="strong">NEW: Recall a BACS payment in Commercial Banking Online</p>
                <p>To get started, ask your Administrator to give you BACS recall role.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>

<style scoped>

    .banner {
        background: #FFC764;
        color: #003F71;
        padding: 13px 0 13px 0;
        min-height: 75px;
    }

    .banner * {
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
    }

    .wrapper {
        max-width: 930px;
        margin: 0 auto;
        position: relative;
    }

    .banner p {
        margin: 0;
        padding: 0;
        font-size: .9375em;
    }

    .banner p.strong {
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        font-size: 1.125em;
    }

    .banner .links {
        float: right;
        width: 30%;
    }

    .banner .ext-link {
        display: block;
        color: #003F71;
        position: relative;
        padding-right: 25px;
        text-align: right;
        font-size: 1.0625em;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        font-weight: 500;
        line-height: 26px;
        text-decoration: none;
    }

    .banner .ext-link:hover {
        text-decoration: underline;
    }

    .banner .ext-link:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: rotate(45deg) skew(-5deg, -5deg);;
        -ms-transform: rotate(45deg) skew(-5deg, -5deg);;
        color: #003F71;
        margin: 0 0 0 5px;      
    }

    @media only screen and (max-width: 966px) {
        .wrapper {
            margin: 0 18px;
        }
    }

    @media only screen and (max-width: 650px) {
        .banner {
            height: auto;
        }

        .banner .text, .banner .links {
            float: none;
            width: 100%;
        }

        .banner .links {
            margin-bottom: 15px;
        }

        .banner .ext-link {
            text-align: left;
        }
    }

</style>
