<template>
    <div class="popular-topics">
        <h3>Popular topics</h3>
        <ul v-if="topics.length > 0">
            <li v-for="(item, index) in this.randomiseList" :key="index">
                <template v-if="item.path.length > 0">
                    <a :href="'/' + item.path + '/article/' + titleToLink(item.faqdisplay)" :title="item.faqdisplay" :data-recid="item.recognition_id" :data-ansid="item.answer_id" :data-category="item.category" @click.prevent="submitRequest(item)">{{ item.faqdisplay }}</a>
                </template>
                <template v-else>
                    <a :href="'/article/' + titleToLink(item.faqdisplay)" :title="item.faqdisplay" :data-recid="item.recognition_id" :data-ansid="item.answer_id" :data-category="item.category" @click.prevent="submitRequest(item)">{{ item.faqdisplay }}</a>
                </template>
            </li>
        </ul>        
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'MainPageFaqSelectorTopics',
    props: ['topics'],
    data() {
        return {
            limit: 20
        }
    },
    methods: {
        submitRequest(faq) {
            let title = faq.faqdisplay;
            title = title.toLowerCase();
            title = title.replace(/\ /g, '-');
            title = title.replace(/\?/gi, '');

            store.dispatch('setFaqDestinationId', faq.answer_id);
            router.push({path: faq.path + '/article/' + title});
        },

        titleToLink(title) {
            let link = title;
            link = link.toLowerCase();
            link = link.replace(/ /g, '-');
            link = link.replace(/\?/gi, '');
            return link;
        }
    },
    computed: {
        randomiseList() {
            let s = this.topics.slice().sort(() => 0.5 - Math.random());
            return s.slice(0, this.limit);
        }
    }
}
</script>

<style scoped>

    .popular-topics {
        width: 48.5%;
        position: relative;
        background: #ffffff;
        padding: 25px;  
    }

    .popular-topics h3 {
        font-size: 1.5em;
        margin: 0 0 25px 0;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        color: #05286A;
    }

    .popular-topics ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.125em;
    }

    .popular-topics ul li a {
        padding: 0 0 10px 0;
        display: block;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        font-size: 1.0625rem;
    }

    .popular-topics ul li a:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: translateY(-50%) rotate(45deg) skew(-6deg, -6deg);
        color: #005BA2;
        margin: 0 0 -5px 10px;
    }

    @media only screen and (max-width: 650px) {
        .popular-topics {
            width: 100%;
            margin-bottom: 30px;
        }
    }

</style>
