<template>
    <div class="inline-search" v-if="flag.length > 0">
        <h2>Any questions?</h2>
        <p>Find an answer by typing your question below.</p>
        <div class="search-field">
            <div class="va-inp">
                <div class="va-inp-in">
                    <div class="va-txt-pod">
                        <input type="text" placeholder="Search..." class="va-inp-txt" id="entry" name="entry" tabindex="0" autocomplete="off" v-on:keyup.13="submitQuestion" @input="submitAutocompleteQuestion" v-on:keyup.down="nextItem" v-on:keyup.up="prevItem" v-model="question" data-tealium-id="menusearch" data-tealium-tracking="none" ref="input" aria-label="Please enter your search query"/>
                    </div>
                    <div class="va-btn-pod">
                        <button type="submit" name="send" class="va-inp-btn" tabindex="0" @click.prevent="submitQuestion" data-tealium-tracking="full" data-tealium-value="$menusearch" data-tealium-narrative="Main Menu Search">Search</button>
                    </div>
                </div>
            </div>
            <template v-if="this.question != '' && this.showAutocomplete">
                <single-page-inline-search-autocomplete :query="question" :autocomplete="autocompleteData" :count="counter" :origin="componentOrigin"></single-page-inline-search-autocomplete>
            </template>
        </div>

    </div>
</template>

<script>

import {Event} from '../event';
import store from '../store';
import router from '../router';

import SinglePageInlineSearchAutocomplete from '@/components/SinglePageInlineSearchAutocomplete';

export default {
    name: 'SinglePageInlineSearch',
    components: {
        SinglePageInlineSearchAutocomplete
    },
    props: ['flag'],
    data() {
        return {
            question: '',
            timer: null,
            interval: null,
            counter: -1,
            componentOrigin: "inline",
            debug: true,
            showAutocomplete: false,
        }
    },

    beforeRouteLeave(to, from, next) {
        this.question = '';
        next();
    },

    methods: {
        resetAutocomplete: function() {
            this.counter = -1;
            this.showAutocomplete = false;
            store.dispatch('setAutocompleteResponseData', null);
        },

        submitQuestion: function() {
            if(!this.question) {
                return false;
            }
            else {
                let entry = this.question;
                this.question = '';
                if(this.counter != -1) {
                    entry = this.autocompleteData.AutoComplete[this.counter].QuestionText;
                }

                this.resetAutocomplete();
                if(!this.isAutocompleteRequestFinished) {
                    this.debug && console.log('[AUTOCOMPLETE] Autocomplete request not finished, setting interval to wait');
                    this.interval = setInterval(() => {
                        if(this.isAutocompleteRequestFinished) {
                            this.debug && console.log('[AUTOCOMPLETE] Autocomplete request finished, submitting normal request');
                            router.push({ path: '/search', query: {q: entry, rf: 'GettingStarted'}});
                            clearInterval(this.interval);
                            this.interval = null;
                        }
                    }, 100);
                }
                else {
                    router.push({ path: '/search', query: {q: entry, rf: 'GettingStarted'}});
                }
            }
        },

        submitAutocompleteQuestion: function() {
            if(this.isAutocompleteRequestFinished) {
                if(this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    if(this.question.length > 2) {
                        //Event.$emit('va.autocomplete', {entry: this.question});
                        Event.$emit('va.request', {type: 'autocomplete', question: this.question});
                        this.showAutocomplete = true;
                    }
                    else {
                        this.resetAutocomplete(); 
                    }
                }, 100);
            }
        },

        nextItem: function(event) {
            if(this.counter < this.autocompleteData.AutoComplete.length - 1) {
                this.counter++;
            }
            else {
                this.counter = 0;
            }
        },

        prevItem: function(event) {
            if(this.counter < this.autocompleteData.AutoComplete.length && this.counter != -1 && this.counter != 0) {
                this.counter--;
            }
            else {
                this.counter = this.autocompleteData.AutoComplete.length - 1;    
            }
        },

        handleClickOutside: function(e) {
            if(!document.getElementsByClassName('va-inp-in')[0].contains(e.target)) {
                this.resetAutocomplete();
            }
        },

        handleEscPress: function(e) {
            if(e.keyCode == 27) {
                this.resetAutocomplete();
            }
        }
    },

    computed: {
        autocompleteData() {
            return store.state.autocompleteResponseData;
        },

        isAutocompleteRequestFinished() {
            return store.state.isAutocompleteAjaxDone;
        },
    },

    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('keyup', this.handleEscPress);
    },

    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('keyup', this.handleEscPress);
    },

    beforeRouteLeave(to, from, next) {
        this.question = '';
        this.counter = -1;
        next();
    },
}
</script>

<style scoped>

    /*
    .main-menu-search {
        height: 0;
        top: 48px;
        background: #006A4D;
        z-index: 10;
        left: 0;
        right: 0;
        transition: opacity .2s, height .3s;
        opacity: 0;
    }

    .main-menu-search.active {
        height: 75px;
        opacity: 1;
        box-shadow: inset 0 6px 9px -4px rgba(50,50,51,0.54);
    }
    */

    .inline-search {
        background: #005BA2;
        padding: 24px;
        margin-bottom: 30px;
        position: relative;
    }

    .inline-search h2, .inline-search p {
        color: #ffffff;
    }

    .inline-search h2 {
        font-weight: 100;
        font-size: 1.5em;
        margin: 0;
        padding: 0;
    }

    .search-field {
        max-width: 930px;
        margin: 0 auto;
        position: relative;
    }

    .va-inp {
        padding-top: 14px;
    }

    .va-inp * {
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
    }

    .va-inp-in {
        position: relative;
    }

    /*
    .va-inp-in:focus {
    }
    */

    .va-txt-pod {
        margin-right: 140px;
        position: relative;
    }

    .va-inp-txt {
        border: none;
        line-height: 48px;
        background: #ffffff;
        height: 48px;
        outline: none;
        color: #707070;
        box-shadow: none;
        width: 100%;
        padding: 0 17px 0 17px;
        font-size: 1.25em;
    }

    ::placeholder {
        color: #ffffff;
    }

    .va-inp-txt:focus {
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
    }

    .va-btn-pod {
        position:absolute;
        right:0;
        top:0;
    }

    .va-inp-btn {
        display: inline-block;
        width: 120px;
        height: 48px;
        background: #048161;
        color: #ffffff;
        text-align: left;
        text-decoration: none;
        line-height: 48px;
        transition: all 0.5s;
        border: none;
        position: relative;
        padding: 0 0 0 18px;
        font-size: 1.125em;
    }

    .va-inp-btn:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: block;
        height: 6px;
        right: 26px;
        position: absolute;
        width: 6px;
        top: 50%;
        margin: auto;
        transform: translateY(-50%) rotate(45deg);
        color: #ffffff;
    }

    .va-inp-btn:hover, .va-inp-btn.activecta {
        cursor: pointer;
        /*
        box-shadow: inset 0px 0px 0px 2px #ffffff;
        color: #ffffff;
        */
    }

</style>
