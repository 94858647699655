<template>
    <div class="main-header">
        <div class="wrapper">
            <a href="#" class="main-logo" title="Bank of Scotland" @click.prevent="goHome">Bank of Scotland</a>
            <a href="https://cbonline.bankofscotland.co.uk/PrimaryAuth/" class="login" target="_blank" rel="noopener" title="Login">Login</a>
            <h1>Commercial Banking Online <br /><span>Help Centre</span></h1>
        </div>
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'MainHeader',

    computed: {
        currentRoute() {
            return this.$route;
        },

        customPages() {
            return store.state.customPages;
        },

        isCustomPage() {
            let found = false;
            let route = this.currentRoute;
            let pages = this.customPages;

            for(var key in pages) {
                if((route.path == '/search' && pages[key].query == route.query.q) || pages[key].path == route.path) {
                found = true;
                break;
                }
            }
            return found;
        }
    },

    watch: {
        currentRoute(n) {
            return n;
        },

        customPages(n) {
            return n;
        },

        isCustomPage(n) {
            return n;
        }
    },

    methods: {
        goHome() {
            if(this.isCustomPage) {
                return false;
            }
            else {
                router.push({ path: '/'});
            }
        }
    }
}

</script>

<style scoped>
    
    .main-header {
        background: #05286A;
    }

    .wrapper {
        height: 96px;
    }

    h1 {
        color: #ffffff;
        font-size: .9375em;
        position: absolute;
        right: 105px;
        text-align: right;
        top: 28px;
        margin: 0;
        font-weight: normal;
        /*letter-spacing: 2.02px;*/
        padding: 0;
        line-height: 24px;
    }

    h1 span {
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
    }

    .main-logo {
        display: block;
        text-decoration: none;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-position: 0 0;
        background-size: 300px 300px;
        width: 228px;
        height: 51px;
        top: 0;
        bottom:0;
        margin: auto;
        position: absolute;
    }

    .login {
        /*
        background: #005BA2;
        height: 36px;
        width: 116px;
        line-height: 38px;
        color: #ffffff;
        position: absolute;
        text-decoration: none;
        display: block;
        padding: 0 0 0 38px;
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
        font-weight: 500;
        font-size: .875em;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all .2s;
        */

        background: #048161;
        transition: all .2s;
        height: 96px;
        width: 84px;
        color: #ffffff;
        text-decoration: none;
        position: absolute;
        right: 0;
        top: 0;
        font-size: .875em;
        display: block;
        text-align: center;
        padding-top: 60px;
    }

    .login:hover {
        background: #157EB3;
        text-decoration: underline;
    }

    .login:before {
        content: '';
        display: block;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-position: -50px -150px;
        background-size: 300px 300px;
        width: 19px;
        height: 26px;
        top: 23px;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;

    }

    /*
    .login:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 5px;
        width: 5px;
        transform: translateY(-50%) rotate(45deg);
        color: #ffffff;
        margin: 0 0 -4px 8px;
    }
    */

    @media only screen and (max-width: 966px) {
        .main-header {
            padding: 0 18px 0 18px;
        }
    }

    @media only screen and (max-width: 650px) {
        .wrapper {
            height: 72px;
        }

        .main-logo {
            /*background-position: -157px 0;*/
            background-size: 220px 220px;
            width: 54px;
            height: 38px;
        }

        h1 {
            top: 16px;
            right:160px;
        }

        .login {
            right: 64px;
            height: 72px;
            padding-top: 48px;
        }

        .login:before {
            top: 16px;
        }

    }

    @media only screen and (max-width: 500px) {
        h1 {
            top: 16px;
            right: 160px;
            font-size: .75em;
            width: 220px;      
        }
    }

    @media only screen and (max-width: 450px) {
        h1 {
            width: 200px;
            right: 160px;
            top: 16px;
            font-size: .5em;
        }
    }

    @media only screen and (max-width: 410px) {
        h1 {
            display: none;
        }
    }
    
</style>
