<template>
    <div class="answer-wrapper" ref="content">
        <div v-html="response"></div>
        <!--<div v-html="this.test"></div>-->
        <iframe id="printpage" name="printpage" src="about:blank"></iframe>
        <div id="printstyle">.step-control,.controls-wrap,.print-button{display:none}img{width:100%}ul{margin:0;padding:0;list-style:none}li{overflow:auto;clear:both;border-bottom:1px solid #cccccc;padding-bottom:30px;margin-bottom:30px;}@font-face {font-family: "LBJ Regular";src: url('/fonts/lloyds_bank_jack-mediumWEB.73837e02.eot'), url('/fonts/lloyds_bank_jack-mediumWEB.a937c797.ttf') format('truetype');font-weight: normal;font-style: normal;}*{font-family:"LBJ Regular", Arial, Helvetica, sans-serif;color:#000000;}img{height:auto;max-width:33%;display:block;margin:0 30px 30px 0;float:left;border:1px solid #BFBFBF;}@page{size:landscape}</div>
        <template v-if="typeof semanticfaqs != 'undefined' && semanticfaqs.length > 0">
            <single-page-answer-semantics :semantics="semanticfaqs"></single-page-answer-semantics>
        </template>
    </div>
</template>

<script>
import SinglePageAnswerSemantics from '@/components/SinglePageAnswerSemantics';
import tealium from '../mixins/tealium.js';

export default {
    name: 'SinglePageAnswer',
    props: ['response', 'semanticfaqs'],
    components: {
        SinglePageAnswerSemantics
    },
    data() {
        return {
            debug: false
        }
    },
    mixins: [tealium],
    mounted() {

        this.debug && console.log('[SINGLE PAGE ANSWER] Answer is being parsed');
        this.debug && console.log('[SINGLE PAGE ANSWER] Answer ref:');
        this.debug && console.log(this.$refs['content']);

        // video setup and injection
        const videos = this.$refs['content'].querySelectorAll('.video');
        videos.forEach(video => {
            if(video.getAttribute('data-guid') !== null && video.getAttribute('data-guid') != '') {
                KV.widget({
                    guid: video.getAttribute('data-guid'),
                    type: video.getAttribute('data-type') !== null ? video.getAttribute('data-type') : '',
                    selector: video,
                    width: video.getAttribute('data-width') !== null ? video.getAttribute('data-width') : '',
                    height: video.getAttribute('data-height') !== null ? video.getAttribute('data-height') : '',
                    hideEmbedButton: true,
                    hideDownloadButton: true,
                });
            }
        });

        // Scroll to
        const scrollLinks = this.$refs['content'].querySelectorAll('.scrollto');
        scrollLinks.forEach(scrollLink => {
            scrollLink.addEventListener('click', (e) => {
                e.preventDefault();
                document.querySelector(scrollLink.getAttribute('href')).scrollIntoView();
            });
        });
        
        // table sorting
        const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;
        const comparer = (idx, asc) => (a, b) => ((v1, v2) => 
            v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
            )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));


        const tables = this.$refs['content'].querySelectorAll('.table');
        //const tableColumns = this.$refs['content'].querySelectorAll('th');

        tables.forEach(tbl => {
            var tableColumns = tbl.querySelectorAll('th');
            tableColumns.forEach(th => th.addEventListener('click', (() => {
                const table = th.closest('table');

                tableColumns.forEach(el => el.classList.remove('active'));
                th.classList.add('active');

                Array.from(table.querySelectorAll('tr:nth-child(n+2)'))
                    .sort(comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
                    .forEach(tr => table.appendChild(tr) );
            })));
        });
        
        // collapsible elements
        this.$refs['content'].querySelectorAll('.tab').forEach(el => {
            el.addEventListener('click', () => {
                el.classList.contains('active') ? el.classList.remove('active') : el.classList.add('active');
            }, false);
        });
        
        // carousel
        this.$refs['content'].querySelectorAll('.carousel-box').forEach(el => {
            var list = el.querySelector('.carousel');
            var listItems = list.querySelectorAll('ul:scope > li');            

            list.classList.add('is-set');

            // add a wrapper around carousel list
            var carouselWrapper = document.createElement('div');
            carouselWrapper.classList.add('wrap');
            list.parentNode.insertBefore(carouselWrapper, list);
            carouselWrapper.appendChild(list);

            // create carousel control buttons
            var carouselControls = document.createElement('div');
            carouselControls.classList.add('controls-wrap');
            var carouselControlsList = document.createElement('ul');
            carouselControlsList.classList.add('controls');
            for(let key = 1; key < listItems.length + 1; key++ ) {
                let listItem = document.createElement('li');
                (key == 1) && listItem.classList.add('active');
                let button = document.createElement('div');
                button.setAttribute('role', 'button');
                button.appendChild(document.createTextNode(key));
                listItem.appendChild(button);
                carouselControlsList.appendChild(listItem);
            }
            carouselControls.appendChild(carouselControlsList);
            el.insertBefore(carouselControls, carouselWrapper);

            // step control buttons
            let nextButton = document.createElement('button');
            nextButton.classList.add('step-control', 'next');
            nextButton.appendChild(document.createTextNode('Next step'));
            let prevButton = document.createElement('button');
            prevButton.classList.add('step-control', 'prev');
            prevButton.appendChild(document.createTextNode('Previous step'));

            // we will be at the first step therefore do not display the prev button
            prevButton.style.display = 'none';

            el.appendChild(prevButton);
            el.appendChild(nextButton);

            listItems[0].classList.add('is-ref');

            // print button
            let printButton = document.createElement('button');
            printButton.classList.add('print-button');
            printButton.appendChild(document.createTextNode('Print'));
            el.insertBefore(printButton, el.querySelector('.controls-wrap'));
            
            // event listener for carousel controls
            for(let key = 0; key < carouselControlsList.querySelectorAll('div').length; key++) {
                carouselControlsList.querySelectorAll('div')[key].addEventListener('click', () => {
                    
                    // Tealium carousel navigation tracking, they want to know which page is active
                    this.tealiumCarouselNavTracking(key + 1);

                    // show/hide prev button based on whether we're at the first step
                    (key == 0) ? el.querySelector('.prev').style.display = 'none' : el.querySelector('.prev').style.display = '';

                    // check whether we have reached the final step
                    (key == listItems.length - 1) ? (el.querySelector('.next').innerHTML = 'Start again') : (el.querySelector('.next').innerHTML = 'Next step');

                    // prev next reset
                    for(let ind = 0; ind < listItems.length; ind++) {
                        listItems[ind].classList.remove('is-ref');
                    }

                    for(let index = 0; index < carouselControlsList.querySelectorAll('div').length; index++) {
                        carouselControlsList.querySelectorAll('div')[index].parentNode.classList.remove('active', 'visited');
                        if(index < key) {
                            carouselControlsList.querySelectorAll('div')[index].parentNode.classList.add('visited')
                        }
                        if(index == key) {
                            carouselControlsList.querySelectorAll('div')[index].parentNode.classList.add('active');

                            // prev next control
                            list.classList.remove('is-set', 'is-reverse');
                            setTimeout(function(){ list.classList.add('is-set'); }, 25);
                            listItems[key].classList.add('is-ref');
                        }
                        
                        //if(index > key) {}
                    }
                });
            }
            
            // event listener for "Next" button
            el.querySelector('.next').addEventListener('click', () => {
                let y = 0;
                list.classList.remove('is-set', 'is-reverse');
                setTimeout(function(){ list.classList.add('is-set'); }, 25);
                for(let key in listItems) {
                    if(listItems[key].classList.contains('is-ref')) {
                        listItems[key].classList.remove('is-ref');

                        // check whether we have reached the final step
                        (listItems.length == (y + 2)) ? (el.querySelector('.next').innerHTML = 'Start again') : (el.querySelector('.next').innerHTML = 'Next step');
                        
                        if(y == listItems.length - 1) { // we have reached the end, restarting the loop
                            listItems[0].classList.add('is-ref');
                            carouselControlsList.querySelectorAll('div')[0].click();

                            // hiding the prev button
                            el.querySelector('.prev').style.display = 'none';
                        }
                        else {
                            listItems[y+1].classList.add('is-ref');
                            carouselControlsList.querySelectorAll('div')[y+1].click();

                            // showing the prev button
                            el.querySelector('.prev').style.display = '';
                        }

                        //(y == listItems.length - 1) ? listItems[0].classList.add('is-ref') : listItems[y+1].classList.add('is-ref');
                        //(y == listItems.length - 1) ? carouselControlsList.querySelectorAll('div')[0].click() : carouselControlsList.querySelectorAll('div')[y+1].click();
                        return false;
                    }
                    y++;
                }
            }, false);

            // event listener for "Prev" button
            el.querySelector('.prev').addEventListener('click', () => {

                // if first step, don't allow "Previous step"
                /*
                if(list.querySelectorAll('li')[0].classList.contains('is-ref')) {
                    return false;
                }
                */

                var y = 0;
                list.classList.remove('is-set');
                list.classList.add('is-reverse');
                setTimeout(function(){ list.classList.add('is-set'); }, 25);

                for(let key in listItems) {
                    if(listItems[key].classList.contains('is-ref')) {
                        listItems[key].classList.remove('is-ref');

                        if(y != 0) {
                            listItems[y-1].classList.add('is-ref');
                            carouselControlsList.querySelectorAll('div')[y-1].click();

                            // showing/hiding the prev button
                            (y == 1) ? el.querySelector('.prev').style.display = 'none' : el.querySelector('.prev').style.display = '';
                        }
                        // check whether we have reached the final step
                        //(y == 0) ? (el.querySelector('.next').innerHTML = 'Start again') : (el.querySelector('.next').innerHTML = 'Next step');

                        //(y == 0) ? listItems[listItems.length-1].classList.add('is-ref') : listItems[y-1].classList.add('is-ref');
                        //(y == 0) ? carouselControlsList.querySelectorAll('div')[listItems.length-1].click() : carouselControlsList.querySelectorAll('div')[y-1].click();
                        return false;
                    }
                    y++;                    
                }
            }, false);

            // event listener for print button
            el.querySelector('.print-button').addEventListener('click', () => {
                /*
                let style = document.getElementById('printstyle').innerHTML;
                let content = el.innerHTML;
                window.frames['printpage'].document.title = document.title;
                window.frames['printpage'].document.body.innerHTML = '<style>' + style + '</style>' + content;
                window.frames['printpage'].window.focus();
                
                // we need a little pause as print is called too quickly before images get rendered
                setTimeout(() => {
                    window.frames['printpage'].window.print();
                }, 1000);
                */

                let style = document.getElementById('printstyle').innerHTML;
                let content = el.innerHTML;
                content = content.replace(/src="\/media\/image/g, 'src="' + window.location.origin + '/media/image');
                let newWin = window.open();
                newWin.document.title = document.title;
                newWin.document.body.innerHTML = '<style>' + style + '</style>' + content;
                newWin.document.close();
                newWin.focus();

                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100);

                //newWin.print();
                //newWin.close();
                             
            });
            
        });
        

    }, // end of mounted
}
</script>

<style scoped>

    .answer-wrapper >>> * {
        color: #333333;
        /*font-size: 1.04em;*/
        line-height: 26px;
    }

    .answer-wrapper {
        margin-bottom: 30px;
    }

    .answer-wrapper >>> a {
        color: #005BA2;
        text-decoration: underline;
    }

    .answer-wrapper >>> a:hover {
        text-decoration: none;
    }

    .answer-wrapper >>> p {
        font-size: 1.125em;
        font-weight: 300;
    }

    .answer-wrapper >>> .strong {
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif !important;
    }

    .answer-wrapper >>> img {
        width: 100%;
    }

    .answer-wrapper >>> ul {
        list-style-type: disc;
    }
    
    .answer-wrapper >>> a.more {
        display: block;
        border: 2px solid #005BA2;
        background: #ffffff;
        font-size: 1.0625em;
        color: #005BA2;
        transition: all .2s;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        height: 48px;
        /*width: 147px;*/
        line-height: 48px;
        text-align: center;
        text-decoration: none;
    }

    .answer-wrapper >>> a.more:hover {
        color: #ffffff;
        background: #005BA2;
        cursor: pointer;
    }

    .answer-wrapper >>> .table {
        /*box-shadow: inset 0 4px 0 0 #006A4D, 0 0 5px 0 rgba(173,173,173,0.5);*/
        background: #ffffff;
        margin: 30px 0 30px 0 ;
    }

    .answer-wrapper >>> table {
        background: #ffffff;
        width: 100%;
        font-size: .875em;
        border-collapse: collapse;
        font-weight: 500;
    }

    .answer-wrapper >>> .table:nth-last-child(1) {
        margin: 30px 0 0 0;
    }

    .answer-wrapper >>> table caption {
        padding: 25px 15px 25px 15px;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-size: 1.5em;
        color: #231B68;
        text-align: left;
        background: #ffffff;
    }

    .answer-wrapper >>> table th {
        border-bottom: 1px solid #BFBFBF;
        border-right: 1px solid #BFBFBF;
        border-top: 1px solid #BFBFBF;
        padding: 25px 40px 25px 15px;
        text-align: left;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        position: relative;
        line-height: 120%;
    }

    .answer-wrapper >>> table th:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: block;
        height: 7px;
        right: 15px;
        position: absolute;
        width: 7px;
        top: 55%;
        margin: auto;
        transform: translateY(-50%) rotate(-45deg) skew(-5deg, -5deg);
        color: #005BA2;
    }

    .answer-wrapper >>> table th.active:after {
        color: #ffffff;
    }

    .answer-wrapper >>> table th.active:before {
        width: 22px;
        height: 22px;
        background: #005BA2;
        border-radius: 50%;
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        right: calc(15px - 6px);
    }

    .answer-wrapper >>> table th:hover {
        cursor: pointer;
    }
    /*
    .answer-wrapper >>> table th:first-child {
        background: #F1F1F1;
    }
    */

    .answer-wrapper >>> table th:last-child {
        border-right: none;
    }

    .answer-wrapper >>> table td {
        padding: 15px;
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif !important;
        border-right: 1px solid #DDDDDD;
        transition: all .2s;
        line-height: 20px;
        font-weight: 300;
    }

    /*
    .answer-wrapper >>> table tr:nth-child(odd) td, .answer-wrapper >>> table tr:nth-child(odd) td[rowspan] {
        background: #F1F1F1;
    }

    .answer-wrapper >>> table tr:nth-child(even) td, .answer-wrapper >>> table tr:nth-child(even) td[rowspan] {
        background: #FFFFFF;
    }
    */

    .answer-wrapper >>> table tr:nth-child(odd) td {
        background: #E6EFF6;
    }

    .answer-wrapper >>> table tr:nth-child(even) td {
        background: #FFFFFF;
    }

    .answer-wrapper >>> table tr td[rowspan] {
        background: #E6EFF6 !important;
    }

    .answer-wrapper >>> table tr td[colspan] {
        background: #E3E3E3 !important;
    }

    .answer-wrapper >>> table tr td.space {
        padding: 2px !important;
    }

    .answer-wrapper >>> table img {
        width: 100% !important;
    }

    /*
    .answer-wrapper >>> table tr:nth-child(even) td {
        background: #F1F1F1;
    }

    .answer-wrapper >>> table tr:nth-child(even) td:first-child {
        background: #E3E3E3;
    }

    .answer-wrapper >>> table tr td:first-child:not(:only-child) {
        background: #F1F1F1;
        border-right: 1px solid #BFBFBF;
    }
    
    .answer-wrapper >>> table tr:hover td {
        background: #E3E3E3 !important;
    }
    */

    .answer-wrapper >>> table img {
        width: 100% !important;
    }

    .answer-wrapper >>> table img.no-stretch {
        width: auto !important;
    }

    .answer-wrapper >>> table span, .answer-wrapper >>> table span.tick {
        text-indent: -9999px;
        position: relative;
        display: block;
    }

    .answer-wrapper >>> table span:after, .answer-wrapper >>> table span.tick:after {
        content: "";
        display: block;
        text-decoration: none;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-position: -210px -200px;
        background-size: 300px 300px;
        width: 16px;
        height: 13px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;        
    }

    .answer-wrapper >>> table p {
        font-size: 1em;
        line-height: 20px;
    }

    .answer-wrapper >>> table p:first-child {
        margin-top: 0;
    }

    .answer-wrapper >>> table p:last-child {
        margin-bottom: 0;
    }












    .answer-wrapper >>> .carousel-box {
        margin-bottom: 30px;
        /*box-shadow: inset 0 4px 0 0 #006A4D, 0 0 5px 0 rgba(173,173,173,0.5);*/
        background: #ffffff;
        position: relative;
        padding: 24px 0 86px 0;
    }

    .answer-wrapper >>> .carousel-box h2 {
        font-size: 1.5em;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        margin: 0 100px 24px 24px;
        color: #05286A;
    }

    .answer-wrapper >>> .carousel-box p {
        margin: 0 0 15px 52%;
        line-height: 28px;
    }

    .answer-wrapper >>> .carousel-box img {
        height: auto;
        max-width: 48%;
        display: block;
        margin: 0 30px 30px 0;
        float: left;
        border: 1px solid #BFBFBF;
    }

    .answer-wrapper >>> .carousel-box img + img {
        width: 48%;
        margin: 0 0 30px 0;
    }






    .answer-wrapper >>> .carousel-box .controls-wrap {
        text-align: center;
        margin: 40px 0 20px 0;
    }

    .answer-wrapper >>> .carousel-box .controls {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 10;
        display: inline-block;
    }

    .answer-wrapper >>> .carousel-box .controls:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background: #BFBFBF;
        top: 24px;
        z-index: 5;       
    }


    .answer-wrapper >>> .carousel-box .controls li {
        display: inline-block;
        margin: 0 18px 0 18px;
        position: relative;
    }

    .answer-wrapper >>> .carousel-box .controls li:first-child {
        margin: 0 18px 0 0;
    }

    .answer-wrapper >>> .carousel-box .controls li:last-child {
        margin: 0 0 0 18px;
    }

    .answer-wrapper >>> .carousel-box .controls li div {
        border-radius: 50%;
        border: 2px solid #BFBFBF;
        background: #ffffff;
        width: 48px;
        height: 48px;
        color: #BFBFBF;
        font-size: 1.25em;
        text-align: center;
        line-height: 46px;
        transition: background .2s, border .2s;
        box-shadow: 0 0 0 2px #ffffff;
        z-index: 15;
        position: relative;
    }

    .answer-wrapper >>> .carousel-box .controls li div:hover, .answer-wrapper >>> .carousel-box .controls li.active div {
        cursor: pointer;
        background: #05286A;
        color: #ffffff;
        border: 2px solid #05286A;
    }

    .answer-wrapper >>> .carousel-box .controls li.visited div {
        background: #05286A;
        text-indent: -9999px;
        border: 2px solid #05286A;        
    }

    .answer-wrapper >>> .carousel-box .controls li.visited div:after {
        content: "";
        display: block;
        text-decoration: none;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-position: -210px -150px;
        background-size: 300px 300px;
        width: 16px;
        height: 13px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
    }

    .answer-wrapper >>> .carousel-box .controls li.active:first-child:before, .answer-wrapper >>> .carousel-box .controls li.visited:first-child:before {
        left: 0;
    }

    .answer-wrapper >>> .carousel-box .controls li.active:before, .answer-wrapper >>> .carousel-box .controls li.visited:before {
        content: "";
        height: 2px;
        background: #006A4D;
        position: absolute;
        top: 24px;
        left: -78px;
        z-index: 6;
        right: 0;
    }











    .answer-wrapper >>> .carousel-box .step-control {
        position: absolute;
        border: none;
        bottom: 24px;
    }

    .answer-wrapper >>> .carousel-box .step-control:hover {
        cursor: pointer;
    }

    .answer-wrapper >>> .carousel-box .next {
        background: #048161;
        color: #ffffff;
        height: 48px;
        width: 128px;
        right: 24px;
        font-size: 1.0625em;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        font-weight: 500;
        padding: 0 0 0 15px;
        text-align: left;
        line-height: 50px;
    }

    .answer-wrapper >>> .carousel-box .next:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: translateY(-50%) rotate(45deg) skew(-6deg, -6deg);
        color: #ffffff;
        margin: 11px 0 0 3px;
        position: absolute;
        top: 13px;        
    }

    .answer-wrapper >>> .carousel-box .prev {
        background: #ffffff;
        color: #005BA2;
        padding: 12px 18px 12px 18px;
        left: 24px;
        font-size: 1.0625em;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        font-weight: 500;
    }

    .answer-wrapper >>> .carousel-box .prev:before {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: translateY(-50%) rotate(-135deg) skew(-6deg, -6deg);
        color: #005BA2;
        margin: 10px 0 0 -15px;
        position: absolute;
        top: 13px;  
    }















    
    .answer-wrapper >>> .wrap {
        overflow: hidden;
    }

    .answer-wrapper >>> .carousel {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        height: 500px;
        overflow: auto;
    }

    .answer-wrapper >>> .carousel li {
        padding: 24px;

        opacity: 0;
        width: 100%;
        height: 100%;
        transition: all .4s;
        position: absolute;
        top: 0;
    }

    .answer-wrapper >>> .carousel li.is-ref {
        opacity: 1;
    }
    

    /*
    This is for IE9. When viewed in IE9 carousel will use a simpler view.
    When viewed in normal browser, feature query will kick in.
    */
    @supports (display: flex) {
        .answer-wrapper >>> .carousel {
            display: flex;
            left: 0;
            transform: translateX(100%);
            height: auto;
            overflow: hidden;
        }

        .answer-wrapper >>> .carousel.is-reverse {
            transform: translateX(-100%);
        }

        .answer-wrapper >>> .carousel.is-set {
            transform: none;
            transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
        }

        .answer-wrapper >>> .carousel li {
            flex: 1 0 100%;
            order: 2;
            opacity: 1;
            transition: none;
            position: static;
        }

        .answer-wrapper >>> .carousel li.is-ref {
            order: 1;
        }        
    }
    
    .answer-wrapper >>> .carousel li ul {
        margin: 0 0 15px 52%;
        padding: 0 0 0 18px;
        font-size: 1.125em;
    }

    .answer-wrapper >>> .carousel li ul li {
        padding: 0 0 10px 0;
        opacity: 1 !important;
        width: auto !important;
        height: auto !important;
        transition: none !important;
        position: static !important;
    }

    .answer-wrapper >>> .carousel li ul li:last-child {
        padding: 0 0 0 0;
    }



























    .answer-wrapper >>> .primary-box {
        background: #ffffff;
        padding: 24px 24px 24px 126px;
        margin-bottom: 30px;
        position: relative;
    }

    .answer-wrapper >>> .primary-box:before {
        content: '';
        position: absolute;
        top: 36px;
        left: 29px;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-size: 300px 300px;
        background-position: -240px -220px;
        width: 59px;
        height: 68px;
    }

    .answer-wrapper >>> .primary-box * {
        color: #333333;
        /*font-size: .875em;*/
    }

    .answer-wrapper >>> .primary-box h3 {
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-size: 1.5em;
        font-weight: bold;
        margin-top: 0;
        color: #05286A;      
    }

    .answer-wrapper >>> .primary-box p {
        font-size: .9375em;
    }

    .answer-wrapper >>> .primary-box .nav-link, .answer-wrapper >>> .primary-box a {
        position: relative;
        font-size: 1.0625rem;
        font-weight: 500;
        transition: all .2s;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        text-decoration: underline;
        color: #005BA2;
    }

    .answer-wrapper >>> .primary-box .nav-link:after, .answer-wrapper >>> .primary-box a:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: translateY(-1px) rotate(45deg) skew(-6deg, -6deg);
        color: #005BA2;
        margin: 0 0 -1px 10px;
        transition: all .2s;
    }

    .answer-wrapper >>> .primary-box .nav-link:hover, .answer-wrapper >>> .primary-box a:hover {
        text-decoration: none;
    }




    .answer-wrapper >>> .container {
        /*box-shadow: inset 0 4px 0 0 #006A4D, 0 0 5px 0 rgba(173,173,173,0.5);*/
        padding: 24px;
        background: #ffffff;
        margin-bottom: 30px;
    }

    .answer-wrapper >>> .container .video {
        margin: 30px 0 30px 0;
    }

    .answer-wrapper >>> .container.video-right {
        width: 52%;
        float: right;
        margin: 0 0 15px 15px;
    }

    .answer-wrapper >>> .container.video-left {
        width: 52%;
        float: left;
        margin: 0 15px 15px 0 ;
    }

    .answer-wrapper >>> .responsive-video {
        position: relative;
        padding-bottom: 45.88%;
        padding-top: 25px;
        height: 0;
    }

    .answer-wrapper >>> .responsive-video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .answer-wrapper >>> .container h3 {
        font-size: 1.5em;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-weight: bold;
        margin-top: 0;
        color: #05286A;
    }

    .answer-wrapper >>> .container * {
        font-size: .9375rem;
    }

    .answer-wrapper >>> .container ol, .answer-wrapper >>> .container ul {
        padding: 0;
        margin: 0 0 20px 28px;
        font-size: 1em;
    }

    .answer-wrapper >>> .container li {
        padding: 0 0 0 10px;
        margin: 0 0 20px 0;
    }

    .answer-wrapper >>> .container img {
        display: inline-block;
        margin: 0 20px 20px 0;
        width: 33%;
    }

    .answer-wrapper >>> .container table {
        font-size: 1.25em;
    }

    .answer-wrapper >>> .container ul li {
        padding: 0;
        margin: 0;
    }











    .answer-wrapper >>> .collapse {
        /*box-shadow: 0 0 5px 0 rgba(173,173,173,0.5);*/
        margin-bottom: 25px;
        background: #ffffff;
    }

    .answer-wrapper >>> .collapse .tab {
        border: none;
        background: none;
        color: #005BA2;
        display: block;
        width: 100%;
        text-align: left;
        padding: 24px 68px 24px 24px;
        font-size: 1.25em;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        position: relative;
    }

    .answer-wrapper >>> .collapse .tab.notification {
        color: #505050;
    }

    .answer-wrapper >>> .collapse .tab span {
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
    }

    .answer-wrapper >>> .red {
        color: #dc2a4d;
    }

    .answer-wrapper >>> .amber {
        color: #d24702;
    }

    .answer-wrapper >>> .green {
        color: #006a4d;
    }

    .answer-wrapper >>> .collapse .tab:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: block;
        height: 10px;
        right: 40px;
        position: absolute;
        width: 10px;
        top: 46%;
        margin: auto;
        transform: translateY(-50%) rotate(135deg) skew(-6deg, -6deg);
        color: #005BA2;
        transform: all .4s;   
    }

    .answer-wrapper >>> .collapse .tab.active:after {
        transform: translateY(-50%) rotate(-45deg)skew(-6deg, -6deg);
    }

    .answer-wrapper >>> .collapse .tab:hover {
        cursor: pointer;
    }

    .answer-wrapper >>> .collapse .tab:focus {
        outline: none;
    }

    .answer-wrapper >>> .content {
        visibility: hidden;
        transition: max-height .2s ease-in, visibility 0s, padding .2s;
        padding: 0 0 0 24px;
        height: 0;
    }

    .answer-wrapper >>> .collapse .content * {
        font-size: .9375rem;
    }

    .answer-wrapper >>> .collapse .tab.active + .content {
        visibility: visible;
        display: block;
        padding: 24px;
        height: auto;
    }

    .answer-wrapper >>> .collapse .content p:last-child {
        margin: 0;
    }

    .answer-wrapper >>> .content h1, .answer-wrapper >>> .content h2, .answer-wrapper >>> .content h3, .answer-wrapper >>> .content h4, .answer-wrapper >>> .content p {
        margin: 0 0 25px 0;
    }

    .answer-wrapper >>> ol li {
        margin-bottom: 15px;
        padding: 0 0 0 5px;
    }

    .answer-wrapper >>> .intro {
        background: #ffffff;
        padding: 24px;
        margin-bottom: 30px;
    }

    .answer-wrapper >>> .intro, .answer-wrapper >>> .lead {
        clear: both;
        overflow: auto;
    }

    .answer-wrapper >>> .intro p:first-of-type {
        margin-top: 0;
    }

    .answer-wrapper >>> .separator, .answer-wrapper >>> .clear-wrap {
        clear: both;
    }

    .answer-wrapper >>> .carousel .clear-wrap {
        clear: none !important;
    }

    .answer-wrapper >>> .intro img {
        float: left;
        width: 33%;
        margin: 0 20px 20px 0;
    }



    .answer-wrapper >>> .video {
        width: 100%;
    }

    .answer-wrapper >>> .video-container p {
        margin: 15px 0 0 0 !important;
        font-size: .875em;
        line-height: 90%;
    }

    .answer-wrapper >>> .video-container img {
        width: 100%;
    }

    .answer-wrapper >>> .video-container.left {
        float: left;
        margin: 20px 30px 30px 0;
        width: 48%;
    }

    .answer-wrapper >>> .video-container.right {
        float: right;
        margin: 0 0 30px 30px;
        width: 48%;
    }






    .answer-wrapper >>> .not-found-panel {
        width: 100%;
        padding: 30px;
        background: #ffffff;
        margin: 0 0 30px 0;
        /*box-shadow: inset 0 4px 0 0 #006A4D, 0 0 5px 0 rgba(173,173,173,0.5);*/
    }

    .answer-wrapper >>> .not-found-panel h4 {
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
        color: #323233;
        font-size: 1.375em;
        margin: 0 0 5px 0;
        font-weight: normal;
    }

    .answer-wrapper >>> .not-found-panel a {
        font-size: 1.125em;
    }

    .answer-wrapper >>> .not-found-panel p {
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
        color: #505050;
        font-size: .9375em;
        margin: 0 0 30px 0;
    }

    .answer-wrapper >>> .not-found-panel a:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 6px;
        width: 6px;
        transform: translateY(-50%) rotate(45deg);
        color: #006A4D;
        margin: 0 0 -4px 5px;
    }








    .answer-wrapper >>> .video-list {
        list-style: none;
        margin: 0 0 35px 0;
        padding: 0 0 0 0;
        display: flex;
        flex-flow:row wrap;
        justify-content: flex-start;
    }

    .answer-wrapper >>> .video-list li {
        display: block;
        /*flex-basis: calc(33.33% - 20px);*/
        margin: 0 30px 30px 0;
        background: #ffffff;
        /*box-shadow: 0 0 5px 0 rgba(173,173,173,0.5);*/
        padding: 24px;
        position: relative;
        width: calc(33.33% - 20px);
    }

    .answer-wrapper >>> .video-list li:nth-child(3),
    .answer-wrapper >>> .video-list li:nth-child(6),
    .answer-wrapper >>> .video-list li:nth-child(9),
    .answer-wrapper >>> .video-list li:nth-child(12),
    .answer-wrapper >>> .video-list li:nth-child(15) {
        margin: 0 0 30px 0;
    }

    .answer-wrapper >>> .video-list h4 {
        font-size: 1.5em;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        margin: 20px 0 20px 0;
        color: #05286A;
        text-align: center;
    }

    .answer-wrapper >>> .video-list p {
        font-weight: 300;
        font-size: .870em;
        margin: 0 0 32px 0;
        padding: 0 0 60px 0;
    }

    .answer-wrapper >>> .video-list a, .answer-wrapper >>> .video-list a.more {
        display: block;
        border: 2px solid #005BA2;
        background: none;
        width: 158px;
        height: 48px;
        font-size: 1.0625em;
        color: #005BA2;
        transition: all .2s;
        position: absolute;
        bottom: 24px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        text-decoration: none;
        /*line-height: 48px;*/
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
    }

    .answer-wrapper >>> .video-list a:hover, .answer-wrapper >>> .video-list a.more:hover {
        background: #005BA2;
        color: #ffffff;
        text-decoration: none;
    }

    .answer-wrapper >>> .video-title {
        font-size: 2.25em;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-weight: bold;
        margin: 20px 0 20px 0;
        color: #05286A;
    }






    .answer-wrapper >>> .va-offer-ics {
        display: none;
    }


    @media only screen and (max-width: 650px) {
        
        .answer-wrapper >>> .carousel-box img {
            max-width: 100% !important;
        }

        .answer-wrapper >>> .carousel-box p {
            margin: 0 0 10px 0;
        }

        .answer-wrapper >>> .carousel-box .controls li {
            margin: 0 10px 10px 10px !important;
        }

        /*
        .answer-wrapper >>> .carousel-box .controls li:first-child {
            margin: 0 10px 0 0;
        }
        */

        .answer-wrapper >>> .carousel-box .controls:before {
            content: none;
        }

        .answer-wrapper >>> .carousel-box .controls li:last-child {
            margin: 0 0 0 10px;
        }

        .answer-wrapper >>> .carousel-box .controls li.active:before, .answer-wrapper >>> .carousel-box .controls li.visited:before {
            left: -58px;
            content: none;
        }

        .answer-wrapper >>> .carousel-box .step-control {
            font-size: .9375em;
        }

        .answer-wrapper >>> .video-list li {
            width: 100%;
            margin: 0 0 30px 0;
        }

        .answer-wrapper >>> .container img {
            width: 100%;
        }
    }

    @media only screen and (max-width: 449px) {
        .answer-wrapper >>> .video-container.right,  .answer-wrapper >>> .video-container.left {
            width: 100%;
            float: none;
            margin: 30px 0 30px 0;
        }
    }

    #printpage {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        white-space: nowrap;
    }

    #printstyle {
        display: none;
    }

    .answer-wrapper >>> .print-button {
        position: absolute;
        right: 24px;
        top: 24px;
        border: none;
        background: none;
        color: #3755A0;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        padding: 0 0 0 30px;
        font-size: 1.0625em;
    }

    .answer-wrapper >>> .print-button:before {
        background-position: -240px 0;
        width: 24px;
        height: 24px;
        display: block;
        content: '';
        text-decoration: none;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-size: 300px 300px;
        top: 0;
        left: 0;
        position: absolute;
    }    

    .answer-wrapper >>> .print-button:hover {
        cursor: pointer;
    }

    .answer-wrapper >>> .update-block {
        margin: 0 0 30px 0;
    }

    .answer-wrapper >>> .update-block * {
        font-size: 1em;
    }

    .answer-wrapper >>> .update-block {
        /*box-shadow: inset 0 4px 0 0 #005BA2, 0 0 5px 0 rgba(173,173,173,0.5);*/
        background: #ffffff;
        padding: 30px 24px;
        position: relative;
        margin: 30px 0 0 0;
    }
    /*
    .answer-wrapper >>> .update-block.archive {
        box-shadow: 0 0 5px 0 rgba(173,173,173,0.5);
    }
    */   

    .answer-wrapper >>> .update-block:last-child {
        margin-bottom: 30px;
    }

    .answer-wrapper >>> .update-block .update-title {
        margin: 0 0 0 0;
        padding: 0 100px 25px 0;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-weight: 500;
        color: #05286A;
        line-height: 30px;
        font-size: 1.5rem;
        border-bottom: 1px solid #EFEDEA;
    }

    .answer-wrapper >>> .update-block .update-date {
        position: absolute;
        right: 24px;
        top: 30px;
        font-size: 1.3125rem;
        color: #05286A;
    }

    .answer-wrapper >>> .update-block .collapse {
        box-shadow: none;
        border-bottom: 1px solid #EFEDEA;
        margin: 0 0 0 0;
    }

    .answer-wrapper >>> .update-block .collapse:first-child {
        border-top: 1px solid #EFEDEA;
    }

    .answer-wrapper >>> .update-block .collapse .content {
       padding: 0;
       overflow: hidden;
    }

    .answer-wrapper >>> .update-block .collapse .content p {
        margin: 0 0 15px 0;
    }

    .answer-wrapper >>> .update-block .collapse .tab {
        padding: 24px 0 24px 0;
        font-size: 1.25rem;
    }

    .answer-wrapper >>> .update-block .collapse .tab.active + .content {
        padding: 0 0 24px 0;
    }

    .answer-wrapper >>> .update-block h4 {
        font-size: 1.3125rem;
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
        font-weight: 500;
    }

    .answer-wrapper >>> .update-block p, .answer-wrapper >>> .update-block ul, .answer-wrapper >>> .update-block, .answer-wrapper >>> .update-block .content {
        font-size: .9375rem;
    }

    .answer-wrapper >>> .update-block .update-table {
        box-shadow: none !important;
    }

    .answer-wrapper >>> .fixed-table table, .answer-wrapper >>> .fixed-columns table {
        table-layout: fixed;
    }

    .answer-wrapper >>> .cta-layout table {
        width: 100%;
    }

    .answer-wrapper >>> .cta-layout table tr td:last-child {
        width: 190px;
        vertical-align: middle;
    }

    .answer-wrapper >>> .cta-layout a.more {
        white-space: nowrap;
        text-decoration: none;
    }

    .answer-wrapper >>> .fixed-table table td:first-child {
        width: 25%;
    }

    .answer-wrapper >>> .fixed-table table td:last-child {
        width: 25%;
        vertical-align: middle;
    }

    .answer-wrapper >>> .update-block .update-table td {
        background: none !important;
        border: none !important;
        vertical-align: top;
    }

    .answer-wrapper >>> .update-table h2 {
        margin: 0;
    }

    .answer-wrapper >>> .update-block table h4 {
        margin: 0 0 15px 0;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-weight: 500;
        color: #05286A;
        font-size: 1.5rem;
    }

    .answer-wrapper >>> .video img {
        width: 100%;
    }

    .answer-wrapper >>> .original-image {
        width: auto !important;
    }

    .answer-wrapper >>> b {
        font-weight: 600;
    }

    .answer-wrapper >>> .tabset {
        margin: 0 0 25px 0;
        display: flex;
        flex-wrap: wrap;
        box-shadow: inset 0 4px 0 0 #005BA2, 0 0 5px 0 hsl(0deg 0% 68% / 50%);
    }

    .answer-wrapper >>> .tabset > input[type="radio"] {
        position: absolute;
        left: -200vw;
    }

    .answer-wrapper >>> .tabset .tab-panel {
        display: none;
    }

    .answer-wrapper >>> .tabset .tab-panels {
        background: #fff;
        width: 100%;
    }

    .answer-wrapper >>> .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
    .answer-wrapper >>> .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
    .answer-wrapper >>> .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
    .answer-wrapper >>> .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
    .answer-wrapper >>> .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
    .answer-wrapper >>> .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
        display: block;
    }

    .answer-wrapper >>> .tabset > label {
        position: relative;
        display: flex;
        border-bottom: 0;
        cursor: pointer;
        font-weight: 100;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        height: 60px;
        line-height: 60px;
        text-align: center;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        padding: 0 20px;
        background: #eee;
        box-shadow: inset 0px -8px 13px -18px rgb(0 0 0 / 90%);
        border-right: 1px solid #fff;
        align-items: center;
        justify-content: center;
    }

    .answer-wrapper >>> .tabset > label span {
        line-height: 130%;
        color: #005BA2;
        font-family: 'Avenir Regular', Arial, Helvetica, sans-serif;
        font-size: 1.125em;
    }

    .answer-wrapper >>> .tabset > label:last-of-type {
        border: none;
    }

    .answer-wrapper >>> .tabset > input:checked + label {
        background: #fff;
        box-shadow: none;
    }

    .answer-wrapper >>> .tabset > input:checked + label span {
        color: #323233;
    }
    
    .answer-wrapper >>> .tab-panel {
        padding: 15px 15px;
    }

    .answer-wrapper >>> .tabset .collapse {
        box-shadow: none !important;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin: 0 0 -1px 0;
    }

    .answer-wrapper >>> h3 {
        margin: 0 0 19px 0;
        padding-top: 20px;
    }


    .answer-wrapper >>> .info-box {
        padding: 12px;
        background: #E5EEF5;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
    }

    .answer-wrapper >>> .info-box:before {
        content:"";
        display: block;
        position:relative;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/info-bos.svg');
        float: left;
        margin-right: 12px;
        background-size: 24px auto;
    }

    @media only screen and (max-width: 650px) {
        .answer-wrapper >>> .info-box:before {
            display: block;
            float:unset;
            margin-bottom: 16px;
        }

      }


</style>
