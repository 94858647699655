<template>
    <div class="page-footer" :class="[view]">
        <div class="wrapper">
            <h5>Need more help?</h5>
            <p class="phone">0808 202 1390</p>
            <p class="time">
                Monday to Friday, 8:00am – 6:00pm. <br />
                <span>Please have your Telephony PIN ready so we can verify your identity.</span> <br />
                If you're outside the UK call us on +44 1264 839 415.
            </p>
            <p class="desc">Calls may be monitored or recorded in case we need to check we have carried out your instructions correctly and to help improve our quality of service. Lloyds Bank plc Registered Office: 25 Gresham Street, London EC2V 7HN. Registered in England and Wales no. 2065. Telephone: 020 7626 1500. Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority under registration number 119278. We subscribe to the Lending Code; copies of the Code can be obtained from www.lendingstandardsboard.org.uk. Eligible deposits with us are protected by the Financial Services Compensation Scheme (FSCS). We are covered by the Financial Ombudsman Service (FOS). Please note that due to FSCS and FOS eligibility criteria not all business customers will be covered.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SinglePageFooter',
    props: ['view']
}
</script>

<style scoped>

    
    .page-footer {
        padding-bottom: 30px;
    }
    

    .wrapper {
        max-width: 930px;
        margin: 0 auto 0 auto;
        padding: 36px 25px 36px 25px;
        background: #ffffff;
    }

    h5 {
        font-size: 1.5em;
        display: inline-block;
        color: #323233;
        margin: 0 0 0 0;
        vertical-align: middle;
        font-weight: 500;
        position: relative;
        padding: 0 0 0 35px;
        /*font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;*/
    }

    h5:before {
        background-position: -210px -220px;
        width: 20px;
        height: 48px;
        display: block;
        content: '';
        text-decoration: none;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-size: 300px 300px;
        top: -8px;
        left: 0;
        position: absolute;
    }

    .phone {
        display: inline-block;
        font-size: 1.5em;
        color: #323233;
        font-weight: 300;
        margin: 0 0 0 15px;
        vertical-align: middle;
    }

    .time {
        color: #323233;
        font-size: .875em;
        padding-left: 20px;
        display: inline-block;
        margin: 0;
        vertical-align: middle;
        font-weight: 300;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
    }

    .time span {
        font-family: 'Avenir Regular', Arial, Helvetica, sans-serif !important;
    }

    .desc {
        font-size: .75em;
        color: #505050;
        margin: 30px 0 0 0;
        font-weight: 300;
    }
    /*
    p.desc {
        margin: 0 0 45px 0;
        padding: 0 0 0 0;
        font-size: 1em;
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
    }
    */

    @media only screen and (max-width: 966px) {
        .page-footer {
            padding: 0 0 0 0;
        }

        .page-footer.clean {
            padding: 0 18px 0 18px;
        }
    }

    @media only screen and (max-width: 650px) {
        h5 {
            margin: 0 0 5px 0;
            display: block;
        }

        .phone {
            margin: 0 0 15px 0;
            display: block;
        }

        .time {
            padding: 0;
            margin-bottom: 15px;
        }
    }

</style>
